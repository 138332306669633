@tailwind base;

@layer base {
  html {
    @apply bg-gray-50 text-gray-900;
  }
}

@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-none {
    font-size: 0;
  }

  .hide-placeholder-onfocus:focus::placeholder {
    color: transparent;
  }

  .hidden-input-type-number-webkit::-webkit-inner-spin-button,
  .hidden-input-type-number-webkit::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .hero-gradient {
    background-color: transparent;
    background-image: radial-gradient(
        at 0% 0%,
        rgb(184, 205, 249, 1) 0,
        rgba(255, 255, 255, 0) 60%
      ),
      radial-gradient(
        at 100% 0%,
        rgb(219, 217, 239, 1) 0,
        rgba(255, 255, 255, 0) 45%
      );
  }
}

.dashed-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E8E8E8' stroke-width='4' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

html,
body,
body > div:first-child,
div#__next,
div#__next > div {
  height: 100%;
}

.dashboard-linear-gradient {
  background: linear-gradient(
    89.96deg,
    #ffffff 52.53%,
    #e1ebf6 75.7%,
    #e0e7ff 93.19%,
    #e5ebff 98.87%
  );
}

/*
* Hubspot form
*/
.hubspot-subscribe-form h2 {
  @apply mb-2 text-base font-bold;
}

.hubspot-subscribe-form p {
  @apply mb-6 text-base text-gray-700;
}

.hubspot-subscribe-form label {
  @apply sr-only;
}

.hubspot-subscribe-form input {
  @apply focus:ring-primary-700 mb-2 w-full rounded border border-gray-300 outline-none placeholder:text-base placeholder:text-gray-500 focus:border-gray-300 focus:ring-2 focus:ring-offset-1 disabled:cursor-not-allowed disabled:border-gray-200 disabled:text-gray-400 disabled:placeholder-gray-400 lg:w-96;
}

.hubspot-subscribe-form input[type='submit'] {
  @apply text-primary-900 border-primary-900 hover:bg-primary-50/50 focus-visible:bg-primary-50/50 inline-flex w-auto cursor-pointer rounded border px-6 py-2 font-medium tracking-[0.0625rem] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 active:shadow-[inset_0_4px_4px_rgba(0,0,0,0.25)] disabled:cursor-not-allowed disabled:border-gray-300 disabled:text-gray-400 disabled:hover:bg-white disabled:active:shadow-none;
}
